// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.augmented-container {
  display: flex;
  align-items: center; /* Vertically centers the items */
  justify-content: center; /* Horizontally centers the items */
  gap: 10px; /* Space between the content and the button */
  padding: 10px;
  text-align: center;
}

.augment-button {
  padding: var(--spacing-lg);
  margin-left: var(--spacing-md);
  font-size: var(--font-size-small-md);
  color: var(--color-white);
  background: linear-gradient(
    45deg, 
    var(--color-link), 
    var(--color-input-bg-dark)
  );
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-fast);
}
.augment-button:hover {
  background: linear-gradient(90deg, var(--color-input-bg), var(--color-border-active));
  transform: var(--transform-lift);
}
.augment-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.markdown-augmented {
  text-align: left; /* Ensures markdown lists are well aligned */
  font-size: var(--font-size-small-lg);
  padding: var(--spacing-md);
  background-color: var(--color-markdown-augmented-bg);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-augmented);
  transition: background-color var(--transition-fast);
}
.markdown-augmented:hover {
  background-color: var(--color-markdown-hover-bg);
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/PromptAugmentation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB,EAAE,iCAAiC;EACtD,uBAAuB,EAAE,mCAAmC;EAC5D,SAAS,EAAE,6CAA6C;EACxD,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B,8BAA8B;EAC9B,oCAAoC;EACpC,yBAAyB;EACzB;;;;GAIC;EACD,YAAY;EACZ,sCAAsC;EACtC,eAAe;EACf,sCAAsC;AACxC;AACA;EACE,qFAAqF;EACrF,gCAAgC;AAClC;AACA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB,EAAE,4CAA4C;EAC9D,oCAAoC;EACpC,0BAA0B;EAC1B,oDAAoD;EACpD,sCAAsC;EACtC,uCAAuC;EACvC,mDAAmD;AACrD;AACA;EACE,gDAAgD;AAClD","sourcesContent":[".augmented-container {\n  display: flex;\n  align-items: center; /* Vertically centers the items */\n  justify-content: center; /* Horizontally centers the items */\n  gap: 10px; /* Space between the content and the button */\n  padding: 10px;\n  text-align: center;\n}\n\n.augment-button {\n  padding: var(--spacing-lg);\n  margin-left: var(--spacing-md);\n  font-size: var(--font-size-small-md);\n  color: var(--color-white);\n  background: linear-gradient(\n    45deg, \n    var(--color-link), \n    var(--color-input-bg-dark)\n  );\n  border: none;\n  border-radius: var(--border-radius-md);\n  cursor: pointer;\n  transition: all var(--transition-fast);\n}\n.augment-button:hover {\n  background: linear-gradient(90deg, var(--color-input-bg), var(--color-border-active));\n  transform: var(--transform-lift);\n}\n.augment-button:disabled {\n  background-color: #cccccc;\n  cursor: not-allowed;\n}\n\n.markdown-augmented {\n  text-align: left; /* Ensures markdown lists are well aligned */\n  font-size: var(--font-size-small-lg);\n  padding: var(--spacing-md);\n  background-color: var(--color-markdown-augmented-bg);\n  border-radius: var(--border-radius-lg);\n  box-shadow: var(--box-shadow-augmented);\n  transition: background-color var(--transition-fast);\n}\n.markdown-augmented:hover {\n  background-color: var(--color-markdown-hover-bg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
