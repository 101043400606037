// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages-page-container {
  max-width: 1000px;
  max-height: 90vh;
  margin: var(--spacing-xxxl) auto;
  overflow-y: auto;
  padding: var(--spacing-xl);
  background: var(--color-settings-bg);
  box-shadow: var(--box-shadow-settings);
  border-radius: var(--border-radius-lg-md);
  font-family: var(--font-family-base);
}

html {
  scroll-behavior: smooth;
}`, "",{"version":3,"sources":["webpack://./src/pages/Messages/styles/Messages.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gCAAgC;EAChC,gBAAgB;EAChB,0BAA0B;EAC1B,oCAAoC;EACpC,sCAAsC;EACtC,yCAAyC;EACzC,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".messages-page-container {\n  max-width: 1000px;\n  max-height: 90vh;\n  margin: var(--spacing-xxxl) auto;\n  overflow-y: auto;\n  padding: var(--spacing-xl);\n  background: var(--color-settings-bg);\n  box-shadow: var(--box-shadow-settings);\n  border-radius: var(--border-radius-lg-md);\n  font-family: var(--font-family-base);\n}\n\nhtml {\n  scroll-behavior: smooth;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
