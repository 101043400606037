// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered {
  justify-content: center;
  display: flex;
  white-space: initial; 
}

.centered i {
  margin: 0 0.2em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Guide/Guide.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".centered {\n  justify-content: center;\n  display: flex;\n  white-space: initial; \n}\n\n.centered i {\n  margin: 0 0.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
