// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-size-slider-container {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.text-size-slider-label {
  margin-right: 10px;
  font-size: 1rem;
}

.slider {
  flex: 1 1;
  margin-right: 10px;
}

.size-display {
  width: 50px;
  text-align: right;
  font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/utils/styles/TextSizeSlider.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".text-size-slider-container {\n  display: flex;\n  align-items: center;\n  margin-top: 50px;\n}\n\n.text-size-slider-label {\n  margin-right: 10px;\n  font-size: 1rem;\n}\n\n.slider {\n  flex: 1;\n  margin-right: 10px;\n}\n\n.size-display {\n  width: 50px;\n  text-align: right;\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
