// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-element {
  position: relative;
}

.min-max-button {
  position: absolute; /* Position the button relative to the parent */
  padding: 10px 10px;
  top: 0px; /* Adjust the vertical position */
  right: 0px; /* Adjust the horizontal position */
  opacity: 0.25; /* Make the button slightly transparent */
  background-color: white; /* Optional: make the background transparent */
  border: none; /* Optional: remove the border */
  cursor: pointer; /* Change cursor to pointer for usability */
}

.min-max-button button:hover {
  opacity: 1; /* Make fully opaque on hover */
}
`, "",{"version":3,"sources":["webpack://./src/utils/styles/expandableElement.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB,EAAE,+CAA+C;EACnE,kBAAkB;EAClB,QAAQ,EAAE,iCAAiC;EAC3C,UAAU,EAAE,mCAAmC;EAC/C,aAAa,EAAE,yCAAyC;EACxD,uBAAuB,EAAE,8CAA8C;EACvE,YAAY,EAAE,gCAAgC;EAC9C,eAAe,EAAE,2CAA2C;AAC9D;;AAEA;EACE,UAAU,EAAE,+BAA+B;AAC7C","sourcesContent":[".expandable-element {\n  position: relative;\n}\n\n.min-max-button {\n  position: absolute; /* Position the button relative to the parent */\n  padding: 10px 10px;\n  top: 0px; /* Adjust the vertical position */\n  right: 0px; /* Adjust the horizontal position */\n  opacity: 0.25; /* Make the button slightly transparent */\n  background-color: white; /* Optional: make the background transparent */\n  border: none; /* Optional: remove the border */\n  cursor: pointer; /* Change cursor to pointer for usability */\n}\n\n.min-max-button button:hover {\n  opacity: 1; /* Make fully opaque on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
