// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProgressBar.module.css */
.progressBarContainer {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 4px;
  margin-top: 10px;
}

.progressBarFill {
  height: 10px;
  background-color: #4caf50;
  border-radius: 4px;
  transition: width 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/utils/styles/ProgressBar.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":["/* ProgressBar.module.css */\n.progressBarContainer {\n  width: 100%;\n  background-color: #f3f3f3;\n  border-radius: 4px;\n  margin-top: 10px;\n}\n\n.progressBarFill {\n  height: 10px;\n  background-color: #4caf50;\n  border-radius: 4px;\n  transition: width 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
