// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  padding: var(--spacing-md);
  border-radius: var(--border-radius-sm);
  border: 2px solid var(--color-input-bg-dark);
  outline: none;
  background-color: var(--color-input-bg);
  color: var(--color-input-text);
  transition: border-color var(--transition-fast);
}
.dropdown:hover,
.dropdown:focus {
  border-color: var(--color-border-active); /* Similar focus effect to input */
}
body.dark-mode .dropdown {
  background-color: var(--color-input-bg-dark);
  color: var(--color-input-text-dark);
  transition: background-color var(--transition-normal), color var(--transition-normal);
}`, "",{"version":3,"sources":["webpack://./src/components/styles/PersonaSelector.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,sCAAsC;EACtC,4CAA4C;EAC5C,aAAa;EACb,uCAAuC;EACvC,8BAA8B;EAC9B,+CAA+C;AACjD;AACA;;EAEE,wCAAwC,EAAE,kCAAkC;AAC9E;AACA;EACE,4CAA4C;EAC5C,mCAAmC;EACnC,qFAAqF;AACvF","sourcesContent":[".dropdown {\n  padding: var(--spacing-md);\n  border-radius: var(--border-radius-sm);\n  border: 2px solid var(--color-input-bg-dark);\n  outline: none;\n  background-color: var(--color-input-bg);\n  color: var(--color-input-text);\n  transition: border-color var(--transition-fast);\n}\n.dropdown:hover,\n.dropdown:focus {\n  border-color: var(--color-border-active); /* Similar focus effect to input */\n}\nbody.dark-mode .dropdown {\n  background-color: var(--color-input-bg-dark);\n  color: var(--color-input-text-dark);\n  transition: background-color var(--transition-normal), color var(--transition-normal);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
