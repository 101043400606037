// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ResizablePane.css */

.resizable-container {
  position: relative;
  height: 100%;
}


.resizer {
  width: 7px;
  cursor: col-resize;
  background-color: #101010;
  transition: background-color 0.2s;
  /* Make the resizer take the full height of the container */
  height: 100%;
  /* Position the resizer between the panes */
    /* No need for absolute positioning since it's within a flex container */
    /* Ensure it's clickable */
  -webkit-user-select: none;
          user-select: none;
  touch-action: none;
}
@media screen and (max-width: 768px){
  .resizer {
    width: 2%;
  }
}

.resizer:hover,
.resizer.resizing {
  background-color: #aaa;
}

/* Optional: Change cursor during resizing */
.resizing {
  cursor: col-resize;
}

/* Optional: Add visual indicators */
.resizer::before {
  content: '';
  display: block;
  height: 30%;
  margin: auto;
  width: 2px;
  background-color: #888;
  border-radius: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/utils/styles/ResizablePane.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB;EACE,kBAAkB;EAClB,YAAY;AACd;;;AAGA;EACE,UAAU;EACV,kBAAkB;EAClB,yBAAyB;EACzB,iCAAiC;EACjC,2DAA2D;EAC3D,YAAY;EACZ,2CAA2C;IACzC,wEAAwE;IACxE,0BAA0B;EAC5B,yBAAiB;UAAjB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE;IACE,SAAS;EACX;AACF;;AAEA;;EAEE,sBAAsB;AACxB;;AAEA,4CAA4C;AAC5C;EACE,kBAAkB;AACpB;;AAEA,oCAAoC;AACpC;EACE,WAAW;EACX,cAAc;EACd,WAAW;EACX,YAAY;EACZ,UAAU;EACV,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["/* ResizablePane.css */\n\n.resizable-container {\n  position: relative;\n  height: 100%;\n}\n\n\n.resizer {\n  width: 7px;\n  cursor: col-resize;\n  background-color: #101010;\n  transition: background-color 0.2s;\n  /* Make the resizer take the full height of the container */\n  height: 100%;\n  /* Position the resizer between the panes */\n    /* No need for absolute positioning since it's within a flex container */\n    /* Ensure it's clickable */\n  user-select: none;\n  touch-action: none;\n}\n@media screen and (max-width: 768px){\n  .resizer {\n    width: 2%;\n  }\n}\n\n.resizer:hover,\n.resizer.resizing {\n  background-color: #aaa;\n}\n\n/* Optional: Change cursor during resizing */\n.resizing {\n  cursor: col-resize;\n}\n\n/* Optional: Add visual indicators */\n.resizer::before {\n  content: '';\n  display: block;\n  height: 30%;\n  margin: auto;\n  width: 2px;\n  background-color: #888;\n  border-radius: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
