// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation {
  background-color: #bababa;
  border-bottom: 1px solid #dee2e6;
  border-radius: 15px;
  margin-top: auto;
  margin-bottom: 2.5%;
}
body.dark-mode .navigation {
  background-color: #161616;
  border-bottom: 1px solid #505254;
}

.nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  margin: 0;
  padding: 0;
}

.nav-item {
  /* Additional styling if needed */
}

.nav-link {
  text-decoration: none;
  color: #343a40;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #007bff;
}

.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/Navigation.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gCAAgC;EAChC,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,OAAO;EACP,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gCAAgC;AAClC","sourcesContent":[".navigation {\n  background-color: #bababa;\n  border-bottom: 1px solid #dee2e6;\n  border-radius: 15px;\n  margin-top: auto;\n  margin-bottom: 2.5%;\n}\nbody.dark-mode .navigation {\n  background-color: #161616;\n  border-bottom: 1px solid #505254;\n}\n\n.nav-list {\n  list-style: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 2%;\n  margin: 0;\n  padding: 0;\n}\n\n.nav-item {\n  /* Additional styling if needed */\n}\n\n.nav-link {\n  text-decoration: none;\n  color: #343a40;\n  font-weight: 500;\n  transition: color 0.3s ease;\n}\n\n.nav-link:hover {\n  color: #007bff;\n}\n\n.active {\n  color: #007bff;\n  border-bottom: 2px solid #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
