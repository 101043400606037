// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.response-textarea {
  max-width: 90%;
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-md);
  outline: none;
}

.response-textarea:focus {
  border-color: var(--color-border-focus);
  box-shadow: 0 0 var(--shadow-focus-size) var(--color-shadow-focus);
}

/* Markdown Suggested Question Text Styling */
.markdown-questions-for-prompt {
  text-align: left; /* Ensures markdown lists are well aligned */
  font-size: var(--font-size-small-lg);
  padding: var(--spacing-xxl);
  background-color: var(--color-markdown-augmented-bg);
  border-radius: var(--border-radius-lg);
  margin-top: var(--spacing-xl);
  box-shadow: var(--box-shadow-augmented);
  transition: background-color var(--transition-fast);
}
.markdown-questions-for-prompt:hover {
  background-color: var(--color-markdown-hover-bg);
}

.questions-list {
  list-style-type: decimal;
  padding-left: var(--spacing-xl);
}

.question-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--spacing-lg-md);
}

.question-text {
  flex: 1 1;
  margin-right: var(--spacing-lg);
}

.response-form {
  flex: 1 1;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/SuggestedQuestions.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,+CAA+C;EAC/C,sCAAsC;EACtC,aAAa;AACf;;AAEA;EACE,uCAAuC;EACvC,kEAAkE;AACpE;;AAEA,6CAA6C;AAC7C;EACE,gBAAgB,EAAE,4CAA4C;EAC9D,oCAAoC;EACpC,2BAA2B;EAC3B,oDAAoD;EACpD,sCAAsC;EACtC,6BAA6B;EAC7B,uCAAuC;EACvC,mDAAmD;AACrD;AACA;EACE,gDAAgD;AAClD;;AAEA;EACE,wBAAwB;EACxB,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mCAAmC;AACrC;;AAEA;EACE,SAAO;EACP,+BAA+B;AACjC;;AAEA;EACE,SAAO;EACP,YAAY;AACd","sourcesContent":[".response-textarea {\n  max-width: 90%;\n  border: 1px solid var(--color-border-secondary);\n  border-radius: var(--border-radius-md);\n  outline: none;\n}\n\n.response-textarea:focus {\n  border-color: var(--color-border-focus);\n  box-shadow: 0 0 var(--shadow-focus-size) var(--color-shadow-focus);\n}\n\n/* Markdown Suggested Question Text Styling */\n.markdown-questions-for-prompt {\n  text-align: left; /* Ensures markdown lists are well aligned */\n  font-size: var(--font-size-small-lg);\n  padding: var(--spacing-xxl);\n  background-color: var(--color-markdown-augmented-bg);\n  border-radius: var(--border-radius-lg);\n  margin-top: var(--spacing-xl);\n  box-shadow: var(--box-shadow-augmented);\n  transition: background-color var(--transition-fast);\n}\n.markdown-questions-for-prompt:hover {\n  background-color: var(--color-markdown-hover-bg);\n}\n\n.questions-list {\n  list-style-type: decimal;\n  padding-left: var(--spacing-xl);\n}\n\n.question-item {\n  display: flex;\n  align-items: flex-start;\n  margin-bottom: var(--spacing-lg-md);\n}\n\n.question-text {\n  flex: 1;\n  margin-right: var(--spacing-lg);\n}\n\n.response-form {\n  flex: 1;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
